.lang-badge {
    box-sizing: border-box;
    min-width: 2.25rem;
    min-height: 2.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #101010;
    border-radius: 34px;
    padding: 0.2rem;
    text-transform: uppercase;
    white-space: nowrap;
    line-height: 1;
}

.lang-badge:hover {
    border-color: #aaa;
}

.lang-badge:focus,
.lang-badge:focus-visible {
    outline-offset: 1px;
    outline: 2px solid #1793c2;
    border-color: transparent;
}

.lang-badge--supported,
.lang-badge--unsupported {
    color: #000000;
}

.lang-badge--supported {
    background-color: #2fce65;
}

.lang-badge--unsupported {
    color: #000000;
    background-color: #ffcc80;
}

.lang-badge__list {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 0.01rem;
    font-size: 14px;
    height: min-content;
}