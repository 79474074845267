/* .info{
    margin-bottom:53px;
} */
.divider .main-content header {
    z-index: 2
}

.inner-content {
    margin-left: 10px;
    overflow-y: 'scroll';
    height: auto;
  }

.tabs-container {
    display: flex;
}

.tab-list {
    overflow-x: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.tab-list::-webkit-scrollbar {
    display: none;
}

.tab-item {
    cursor: pointer;
    margin: 0;
    white-space: nowrap;
    vertical-align: middle;
    text-align: center;
    padding: clamp(0.1rem, 2vw, 0.6rem) clamp(0.1rem, 2vw, 0.6rem);
    border-bottom: 2px solid transparent;
    border-top-left-radius: 0.2rem;
    border-top-right-radius: 0.2rem;
}

.tab-item:focus,
.tab-item:focus-visible,
.tab-item:hover {
    background-color: rgba(23, 147, 194, 0.09);
}

.tab-item--active {
    color: rgba(23, 147, 194, 1);
    border-bottom: 2px solid rgba(255, 255, 255, 1);
}

.scroll-btn-container__left,
.scroll-btn-container__right {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
}

.scroll-btn-container__right {
    right: 0;
}

.scroll-btn-container__left {
    left: 0;
}

.scroll-btn {
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}

.scroll-btn:disabled {
    pointer-events: none;
    opacity: 0.2;
}

.scroll-btn:hover,
.scroll-btn:focus,
.scroll-btn:focus-visible {
    background-color: rgba(23, 147, 194, 0.9);
    transition: all 0.2s ease;
}
